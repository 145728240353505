.question {
    width: 80vw;
    font-weight: 600;
}

.choices-hidden,
.question-hidden {
    visibility: hidden;
}

.player {
    padding: 0.5vw 1.75vw;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.75);

    font-size: 1.5vw;

    text-align: center;
    width: 20%;

    &.hidden {
        visibility: hidden;
    }
}

.question-text {
    width: 100%;

    padding: 1vw 1.5vw;
    text-align: center;
    font-size: 2vw;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.75);
}

.choice {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 0.5vw 1.75vw;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.75);
    border: 0.4vw solid transparent;

    font-size: 1.5vw;
}

.choice-letter {
    font-size: 1.3em;
    font-weight: 900;
    margin-right: 0.5em;
}

.choice-player {
    background-color: rgba(255, 255, 255, 0.5);
}

.choice-correct {
    background-color: green;
}

.choice-chaser {
    border: 0.4vw solid red !important;
}