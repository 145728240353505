.timer-box {
    font-weight: 600;
}

.score {
    padding: 1vw 1vw;
    width: 17.5vw;
    text-align: center;
    font-size: 2vw;

    border-radius: 20px 0px 0px 20px;
    background-color: rgba(0, 0, 0, 0.75);
}

.timer {
    padding: 1vw 3vw;
    text-align: center;
    font-size: 2vw;

    border-radius: 0px 20px 20px 0px;
    background-color: rgba(0, 0, 0, 0.75);
}

.choice-correct {
    background-color: green;
}

.choice-chaser {
    border: 0.4vw solid red !important;
}