.game-buttons {
    .btn {
        width: 100px;
        height: 100px;

        border: 1px solid white;
        color: white;

        &.active {
            background-color: white;
            color: black;
        }
    }
}